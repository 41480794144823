'use client'

import * as React from 'react'
import { useEffect } from 'react'
import type { MainLayoutProps } from './types'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import routes from 'web/src/lib/routes'
import classNames from 'classnames'
import useContactSupportTracking from 'web/src/lib/hooks/useContactSupportTracking'
import { PolicyLink } from '../../components/Footer/types'
import CartButton from '../../components/CartButton'
import { useStore } from 'web/src/lib/context/store-context'
import { useAccount } from 'web/src/lib/context/account-context'
import Carousel from '../../components/Carousel'
import Cart from '../../components/Cart'
import Navigation from '../../components/Navigation'
import QuantityError from '../../components/QuantityError'
import { constants } from '../../constants'

const MainLayout = ({
    children,
    footerDisabled = false,
    backgroundColor,
    headerProps,
    cartButton,
    mealButton,
    showCarousel,
    is404,
    contain,
    noPadding = false,
}: MainLayoutProps) => {
    useContactSupportTracking()
    const { customer } = useAccount()
    const { setShowCart, menuOpen, setMenuOpen } = useStore()
    const socials = constants['socials']
    useEffect(() => {
        setMenuOpen(false)
        // const regex = new RegExp('gerechten\\/[a-zA-Z]')
        // if (!regex.test(location.pathname)) {
        //     window.scroll(0, 0)
        // }
    }, [])

    return (
        <>
            <QuantityError />
            <div className={`${!!backgroundColor ? backgroundColor : null}`}>
                {cartButton ? <CartButton onClick={() => setShowCart(true)} /> : mealButton ? <CartButton /> : <></>}
                <Header
                    accountHeader={headerProps?.accountHeader}
                    useGradient={headerProps?.useGradient}
                    overflow={headerProps?.overflow}
                    dark={headerProps?.dark}
                    checkoutProgress={headerProps?.checkoutProgress}
                    color={headerProps?.color}
                    disableAccountIcon={headerProps?.disableAccountIcon}
                    backButton={headerProps?.backButton}
                    darkOnMobile={headerProps?.darkOnMobile}
                />
                <Navigation
                    navigationOpen={menuOpen}
                    onClose={() => setMenuOpen(!menuOpen)}
                    menuItems={[
                        {
                            label: 'Gerechten',
                            slug: '/gerechten',
                        },
                        {
                            label: 'Blog',
                            slug: '/blog',
                        },
                        {
                            label: 'FAQ',
                            slug: '/veelgestelde-vragen',
                        },
                    ]}
                    account={{
                        label: customer ? 'Mijn account' : 'Inloggen',
                        slug: customer ? routes.account : routes.login,
                    }}
                    contact={{
                        label: 'Neem contact op',
                        slug: 'mailto:info@goodchef.nl',
                    }}
                />
                <section
                    style={contain ? { contain: 'paint' } : {}}
                    className={classNames(`${backgroundColor} flex flex-col px-0`, {
                        'p-0': headerProps?.useGradient || headerProps?.formHeader,
                        'pt-[90px]': !noPadding,
                        'min-h-screen': !is404,
                    })}
                >
                    {children}
                    {footerDisabled && (
                        <div className="mt-auto w-full flex flex-col text-black/50 bg-white border-t-[1px] border-gray items-center justify-center py-5 bottom-0 z-10 h-[75px]">
                            <p className={'font-bold text-xs'}>Hulp nodig bij je bestelling?</p>
                            <a
                                className="text-white/80 lg:text-xs !leading-[48px] lg:!leading-[22px] font-medium"
                                href="mailto:info@goodchef.nl"
                            >
                                Mail naar info@goodchef.nl
                            </a>
                        </div>
                    )}
                </section>
                {showCarousel && <Carousel is404={!!is404} />}

                {!footerDisabled && (
                    <Footer
                        socials={socials}
                        links={[
                            {
                                label: 'Onze maaltijden',
                                slug: routes.products,
                            },
                            {
                                label: 'Veelgestelde vragen',
                                slug: routes.faq,
                            },
                            {
                                label: 'Contact',
                                slug: 'mailto:info@goodchef.nl',
                            },
                        ]}
                        policies={
                            [
                                {
                                    label: 'Voorwaarden',
                                    slug: routes.termsOfUse,
                                },
                                {
                                    label: 'Privacy Policy',
                                    slug: routes.privacyPolicy,
                                },
                                {
                                    label: 'Cookie instellingen',
                                    slug: '#cookie-settings',
                                },
                            ] as PolicyLink[]
                        }
                    />
                )}
            </div>
            <Cart mobile />
        </>
    )
}

export default MainLayout
