import React, { FC, SVGAttributes } from 'react'

const BagIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
    <path
      fill="#242424"
      fillRule="evenodd"
      d="M6.656 5.357v.893h-2.58c-1.247 0-2.291.862-2.422 1.999L.014 22.535c-.036.31 0 .625.105.923.106.297.278.571.507.804.228.232.507.418.819.545.312.127.65.193.99.193h20.13c.34 0 .678-.066.99-.193.312-.127.591-.313.82-.545.228-.233.4-.507.506-.804.105-.298.141-.612.106-.923l-1.64-14.286a2.175 2.175 0 00-.793-1.426 2.564 2.564 0 00-1.63-.573h-2.58v-.893c0-1.42-.616-2.783-1.712-3.788S14.05 0 12.5 0c-1.55 0-3.036.564-4.132 1.57-1.096 1.004-1.712 2.366-1.712 3.787zM12.5 1.786c-1.033 0-2.024.376-2.755 1.046-.73.67-1.141 1.578-1.141 2.525v.893h7.792v-.893c0-.947-.41-1.855-1.141-2.525-.73-.67-1.722-1.046-2.755-1.046zm-3.896 9.821c0 .947.41 1.856 1.141 2.526.73.67 1.722 1.046 2.755 1.046s2.024-.377 2.755-1.046c.73-.67 1.141-1.579 1.141-2.526v-.893c0-.237.103-.464.285-.631a1.02 1.02 0 01.689-.262c.258 0 .506.095.689.262a.857.857 0 01.285.631v.893c0 1.42-.616 2.784-1.712 3.788-1.096 1.005-2.582 1.57-4.132 1.57-1.55 0-3.036-.565-4.132-1.57-1.096-1.004-1.712-2.367-1.712-3.788v-.893c0-.237.103-.464.285-.631a1.02 1.02 0 01.689-.262c.258 0 .506.095.689.262a.857.857 0 01.285.631v.893z"
      clipRule="evenodd"
    />
  </svg>
)

export default BagIcon
