'use client'

import React, { useState } from 'react'
import type { CartButtonProps } from './types'
import CutleryIcon from '../../icons/cutleryIcon'
import BagIcon from '../../icons/bagIcon'
import Button from '../Button'
import classNames from 'classnames'
import { useRouter } from 'next/navigation'
import routes from 'web/src/lib/routes'
import { useCart } from 'medusa-react'

const CartButton = ({ onClick }: CartButtonProps) => {
    const router = useRouter()
    const { cart } = useCart()
    const MealButton = () => (
        <div
            aria-label={'Ga naar gerechten overzicht'}
            className={classNames(
                'lg:right-[158px] lg:hover:rotate-[-1deg] group fixed transition-all opacity-100 duration-200 right-[calc(50%-50px)] xs:bottom-2 bottom-5 lg:bottom-10 z-40 bg-green-brand rounded-full flex items-center justify-center h-[100px] w-[100px] lg:h-[60px] lg:w-[220px] lg:hover:cursor-pointer shadow lg:hover:bg-black lg:hover:text-white transition-all',
            )}
            onClick={() => router.push(routes.products)}
        >
            <div className="flex flex-col lg:flex-row items-center [&>svg]:h-[26px]">
                <CutleryIcon className="relative left-[1px] duration-200 lg:group-hover:fill-green-brand" />
                <span className="uppercase font-bold text-[9px] leading-5 lg:text-base mb-1 lg:mb-0 lg:ml-1.5">
                    Gerechten
                </span>
            </div>
        </div>
    )

    const MyMealBoxButton = () => (
        <div
            className={classNames(
                'lg:hidden lg:right-[158px] lg:hover:rotate-[-6deg] group fixed transition-all opacity-100 duration-200 right-[calc(50%-50px)] xs:bottom-2 bottom-5 lg:bottom-10 z-40 bg-green-brand rounded-full flex items-center justify-center h-[100px] w-[100px] h-[100px] rounded-full lg:hover:cursor-pointer shadow lg:hover:bg-black lg:hover:text-white transition-all',
            )}
            onClick={e => {
                e.preventDefault()
                onClick && onClick()
            }}
        >
            <div className="flex flex-col items-center">
                <div className="left-[-3px] flex flex-row relative">
                    <Button
                        aria-label={'Open mijn maaltijdbox'}
                        className="btn-round transition-all duration-200 bg-white text-dark [&>span]:text-[10px] z-10 relative left-[3px] border-[3px] lg:group-hover:border-black border-green-brand pointer-events-none"
                    >
                        {cart?.items?.reduce((prev, acc) => {
                            return prev + acc?.quantity
                        }, 0)}
                    </Button>
                    <BagIcon className="duration-200 transition-all lg:group-hover:[&>path]:fill-green-brand" />
                </div>
                <span className="uppercase font-bold text-[9px] leading-5">Maaltijdbox</span>
            </div>
        </div>
    )

    return onClick ? <MyMealBoxButton /> : <MealButton />
}

export default CartButton
